.c-subcategory-tiles,
.c-product-tiles {
  @include xy-gutters(
    $gutters: $grid-container-padding,
    $gutter-position: right left,
    $negative: true
  );
  display: flex;
  flex-wrap: wrap;

  .c-loader {
    margin: auto;
  }

  &__container {
    padding: 3.75rem 0;
    margin-bottom: 2.5em;

    &.isAssortment {
      padding: 0;
    }

    &.bg--white {
      .c-product-tile a {
        border: $gb_grey_060 solid 4px;
      }
    }
  }

  &.c-product-tiles__single-column {
    display: block;
  }

  &__column {
    @include xy-gutters(
      $gutters: $grid-container-padding,
      $gutter-type: padding,
      $gutter-position: right left bottom top
    );
    width: 100%;

    @include breakpoint(medium) {
      .c-product-tiles__two-columns &,
      .c-product-tiles__three-columns &,
      .c-product-tiles__single-column &,
      .c-product-tiles__four-columns & {
        width: 50%;
      }

      .c-product-tiles__single-column.is--centered & {
        margin: 0 auto;
      }
    }
    @include breakpoint(large) {
      .c-product-tiles__three-columns & {
        width: 33.33333%;
      }
    }
    @include breakpoint(large) {
      .c-product-tiles__four-columns & {
        width: 25%;
      }
    }
  }

  &--border .c-product-tile > a {
    border: 0.3125rem solid $gb_grey_055;
  }
}

//
// single product tile
// -----------------------------------
.c-product-tile {
  position: relative;
  height: 100%;

  a,
  &--loading,
  &--listing > div {
    display: block;
    height: 100%;
    background-color: $gb-white;
    padding: 0.9375rem 0.625rem 4rem;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      .c-product-tile__title,
      .c-product-tile__subtitle {
        color: var(--gb-brand-primary-light);
      }
      .c-product-tile__title.okv,
      .c-product-tile__subtitle.okv {
        color: #000000;
      }
    }

    .c-product-tile__title.okv {
      text-transform: uppercase;
      font-weight: 300;
    }

    &::before {
      display: none;
    }
  }

  > a,
  &--loading,
  &--listing > div {
    transition: box-shadow $gb_transitionNormal $gb-transitionTiming;

    &:hover,
    &:active,
    &:focus {
      box-shadow: $box-shadow-carousel-hover;
      text-decoration: none;

      .c-product-tile__thumbnails {
        opacity: 1;
      }
    }
  }

  &--loading {
    padding: 6.25rem 0;
    text-align: center;

    .c-loader {
      display: inline-block;
    }
  }

  &__image {
    max-width: 11.25rem;
    height: 11.25rem;
    margin: 0 auto 1.875rem;

    img {
      display: block;
    }

    &.series {
      img {
        min-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 208px;
        object-fit: cover;
      }
    }
  }

  &__title {
    font-weight: $font-weight-light;
    font-size: 1rem;
    text-transform: none;
    margin-bottom: 0;
    color: $gb-black;
    letter-spacing: calc-letter-spacing(20);
    line-height: 1.375rem;
    transition: color $gb_transitionNormal $gb_transitionTiming;
  }

  &__subtitle {
    font-weight: $font-weight-bold;
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-bottom: 0;
    color: $gb-black;
    letter-spacing: calc-letter-spacing(20);
    line-height: 1.375rem;
    transition: color $gb_transitionNormal $gb_transitionTiming;
  }

  &__thumbnails {
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    width: 1.875rem;
    opacity: 0;
    transition: opacity $gb_transitionNormal $gb_transitionTiming;
  }

  &__thumbnail {
    margin-bottom: 10px;
    border: 1px solid $gb_grey_200;

    img {
      max-width: 1.75rem;
      max-height: 1.75rem;
      display: block;
    }
  }

  &__label {
    position: absolute;
    top: 1.875rem;
    right: 0;
    background-color: $gb_brand-utility-warning;
    font-size: 0.8125rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $gb-white;
    line-height: 1;
    padding: 8px 1.25rem 8px 10px;
  }

  &__articles {
    position: absolute;
    bottom: 1.25rem;
    font-weight: $font-weight-medium;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    color: $gb_grey-blue;
  }

  &--listing {
    border: 3px solid $gb_grey_060;

    > div {
      padding: 0;
      width: 100%;
      border: $gb_grey_060 solid 4px;
    }

    button {
      width: 100%;
      font-weight: normal;
      text-align: left;
      padding: 1.25rem 1.25rem 1.875rem 1.25rem;
      display: block;
    }

    &:hover {
      position: relative;
      z-index: 10;

      > div {
        position: absolute;
        height: auto;
        border: none;
      }
    }

    a {
      border-bottom: 1px solid $gb_grey_075;
    }
  }

  &--deprecated {
    border: 2px solid $gb_gdds_warning;
    padding: $spacing-xs;
    color: $gb_gdds_warning;
    background-color: $gb-white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.c-filter-area {
  form {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;

    input[type='checkbox'] + span {
      margin: 0 0 0.5rem 0;

      &::before,
      &::after {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .form-items {
      margin-bottom: 0;
    }
  }

  .c-filter {
    margin-bottom: 2.5em;
  }

  .form-item {
    margin-bottom: 1.5625rem;

    @include breakpoint(large) {
      margin-bottom: 3.75rem;
    }
  }

  &__results-sum {
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
    order: 3;

    h4 {
      margin-bottom: 0;
      line-height: 2.6;
    }

    @include breakpoint(medium) {
      margin-top: 0;
      order: 0;
    }
  }

  &__sort {
    margin-top: 1.875rem;

    .headline-right {
      margin-bottom: 0;
      line-height: 2.6;
    }

    @include breakpoint(medium) {
      margin-top: 0;
      text-align: right;
    }
  }

  &__results h3 {
    line-height: 2.6;

    @include breakpoint(large) {
      margin: 3px 0 0 0;
    }

    &.headline-right {
      @include breakpoint(large) {
        text-align: right;
      }
    }
  }

  .dropdown__trigger {
    padding: 0.625rem 1.25rem;
  }

  .dropdown__content {
    max-height: 20.625rem;
    overflow-y: auto;

    .form-items:not(.form-items--radio-list) {
      padding: 1.25rem;
    }

    li:hover {
      background-color: transparent;
    }
  }
}

@import 'variables';

:root {
  --gb-brand-primary: #{$platform-primary};
  --gb-brand-primary-light: #{$platform-primary-light};
  --gb-brand-primary-dark: #{$platform-primary-dark};
  --product-tiles-new-flag: #{$platform-primary-light};
  --uc-heading-title-font-weight: 400;

  .nord {
    --gb-brand-primary: #{$nord-primary};
    --gb-brand-primary-light: #{$nord-primary-light};
    --gb-brand-primary-dark: #{$nord-primary-dark};
    --product-tiles-new-flag: #{$nord-secondary-emerald};
  }

  .kolo {
    --gb-brand-primary: #{$kolo-primary};
    --gb-brand-primary-light: #{$kolo-primary-light};
    --gb-brand-primary-dark: #{$kolo-primary-dark};
    --product-tiles-new-flag: #{$kolo-secondary-emerald};
    --uc-heading-title-font-weight: 700;
  }

  .twyford {
    --gb-brand-primary: #{$twyford-primary};
    --gb-brand-primary-light: #{$twyford-primary-light};
    --gb-brand-primary-dark: #{$twyford-primary-dark};
    --product-tiles-new-flag: #{$twyford-secondary-emerald};
    --uc-heading-title-font-weight: 700;
  }
}

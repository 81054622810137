.dc-page-headline {
  padding-top: 3.4375rem;

  .c-cart {
    margin-bottom: 0;

    &__box {
      background: $gb_grey_075;
      padding-bottom: 0.9375rem;

      button {
        display: flex;
        font-weight: $font-weight-regular;
        padding: 5px 1.25rem 0.625rem;
        position: relative;
        text-align: left;
        width: 100%;

        span {
          margin-left: 0.625rem;
        }

        i::before {
          font-size: 0.6875rem;
          position: absolute;
          top: 0.625rem;
        }
      }
    }

    i {
      font-size: 1.25rem;
      padding-right: 5px;
    }

    h5 {
      background: var(--gb-brand-primary-light);
      color: $gb_white;
      font-weight: normal;
      font-size: 0.8125rem;
      padding: 0.625rem 1.25rem 0.625rem;
      margin-bottom: 0.625rem;

      i {
        padding-right: 0.625rem;
      }
    }
  }

  .has-intro-text {
    margin-bottom: 3.125rem;
  }
}

.c-cart-downloads:not(.nordics) table {
  margin-bottom: 3.75rem;
}

.c-cart-downloads {
  table {
    width: 100%;

    th {
      font-weight: $font-weight-bold;
      white-space: nowrap;

      &:first-of-type {
        @include breakpoint(medium) {
          width: 31.25rem;
        }
      }
    }

    td:nth-of-type(2) {
      text-align: center;
    }

    button {
      font-weight: normal;
      font-size: 0.75rem;
      padding: 0;
    }
  }

  & > table + button {
    display: flex;

    &.download-icon-animation {
      .icon-download {
        &__icon,
        &::before,
        &::after {
          animation: movedown 0.8s linear infinite;
        }
      }
    }

    &.download-icon-finished {
      .icon-download {
        &__wrapper {
          border-bottom: none;
        }

        &__icon {
          animation: transalteIcon 1.5s forwards;

          &::before {
            animation: translateBefore 1.5s forwards;
          }
          &::after {
            animation: translateAfter 1.5s forwards;
          }
        }
      }
    }

    .icon-download {
      &__wrapper {
        overflow: hidden;
        display: block;
        position: relative;
        z-index: 1;
        height: 16px;
        width: 12px;
        margin-right: 5px;
        border-bottom: 1px solid $gb_white;
      }

      &__icon {
        width: 1.3px;
        height: 12px;
        background-color: $gb_white;
        position: absolute;

        &::before,
        &::after {
          content: '';
          bottom: 0;
          width: 1.2px;
          height: 6px;
          position: absolute;
          background-color: $gb_white;
        }

        &::before {
          left: -2px;
          transform: rotate(315deg);
        }

        &::after {
          right: -2px;
          transform: rotate(45deg);
        }
      }
    }

    .download-button--text {
      position: relative;
      z-index: 1;
    }
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--gb-brand-primary-light);
    height: 100%;
    transition: width 0.2s ease-in;
  }

  .button.button--white.button--background-primary {
    float: left;
  }
}

.c-cart-orders {
  .form-item {
    margin-bottom: 1.5625rem;
  }

  &__table {
    margin-bottom: 3.4375rem;
    width: 100%;

    th {
      font-weight: $font-weight-bold;
    }

    th:nth-of-type(2) {
      width: 6.25rem;
    }

    th:last-of-type {
      width: 9.375rem;
    }

    .button {
      display: inline-flex;
      font-weight: $font-weight-regular;
      padding: 0;
    }

    &--group {
      th:last-of-type {
        width: 10rem;
      }

      td {
        vertical-align: top;
      }
    }
  }

  &__table:not(.nordics) {
    input[type='number'] {
      padding: 5px 0 5px 10px;
      text-align: center;
      width: 100%;
    }
  }

  &__form-button {
    text-align: left;

    p {
      margin-top: 2.5em;
      margin-bottom: 0.9375rem;
    }
  }

  &__headline {
    text-align: left;
  }
}

body {
  .buorg {
    background-color: $gb_black_82;
    border-bottom: none;
    border-top: none;
    color: $gb_white;
    font-family: $body-font-family;
    font-size: 0.875rem;
    text-align: left;
    animation: 0.4s ease-out 0s buorgflyoverride;
  }

  .buorg-icon {
    background: none;
    vertical-align: middle;
    position: relative;
    top: -2px;
    display: initial;
    width: 1.125rem;

    &::before {
      @include web20-icon('information');
    }
  }

  .buorg-mainmsg {
    margin-left: 0.625rem;
  }

  .buorg-moremsg {
    padding-left: 1.625rem;
    display: block;
  }

  #buorgig,
  #buorgul,
  #buorgpermanent {
    cursor: pointer;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-family: AktivGrotesk, helvetica, arial, sans-serif;
    font-weight: 500;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0);
    min-width: 6.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-height: 2.5rem;
    box-shadow: none;
    padding: 0rem 1.5rem;
  }

  #buorgul {
    border: 1px solid $gb_white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 0;
    transition: all 0.2s;

    &:hover {
      background: $gb_white_70;
      border: 1px solid transparent;
      color: $gb_black;
    }
    &:active {
      border: 1px solid $gb_white;
      background: $gb_white;
      color: $gb_black;
    }
  }

  .buorg-buttons {
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0.5rem;
    padding-left: 1.5rem;
    box-sizing: border-box;
    margin-top: 0.75rem;

    a::before {
      display: none;
    }

    @include screen-sm {
      display: flex;
      flex-direction: row;
      padding-left: 0.5rem;
      margin-left: 0.938rem;
    }

    @include screen-md {
      padding: 0;
      position: absolute;
      right: 1.5rem;
      bottom: 50%;
      transform: translateY(50%);
      flex-direction: row-reverse;
    }
  }

  .buorg-test {
    padding: 0.313rem 0.75rem 0.313rem 0.563rem;
    line-height: 1.3em;
  }

  .buorg-pad {
    padding: 1.5rem 3.25rem 1.5rem 1.5rem;
    line-height: 1.25rem;
  }

  @media only screen and (max-width: 700px) {
    .buorg div {
      padding: 1.5rem 3.25rem 1.5rem 1.5rem;
      line-height: 1.25rem;
    }
  }

  .buorg-fadeout {
    transition: visibility 0s 2s, opacity 1.5s ease-out 0.4s;
  }
}

@keyframes buorgflyoverride {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

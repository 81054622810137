.icon {
  &--white {
    &::before {
      color: $gb_white;
    }
  }

  &--black {
    &::before {
      color: $gb_black;
    }
  }

  &--primary {
    &::before {
      color: var(--gb-brand-primary);
    }
  }

  &--primary-dark {
    &::before {
      color: var(--gb-brand-primary-light);
    }
  }
}

$gradient-color-first: 22%;
$gradient-color-last: 55%;

// --------------------------
// Wrapper on which Isotope will be initialized
.c-tiles-homepage {
  padding: 1.875rem 0;

  .tile-sizer {
    visibility: hidden;
    width: 100%;
  }

  @include breakpoint(medium) {
    margin: -0.9375rem -0.9375rem 0;

    .tile-sizer {
      width: 50%;
      aspect-ratio: 1 / 1;
    }
  }

  @include breakpoint(large) {
    margin-top: -0.9375rem;

    .tile-sizer {
      width: 33.333333%;
    }
  }
}

// --------------------------
// Single tile
.c-tile-homepage {
  @include hyphens();
  width: 100%;
  margin-bottom: 1.875rem;
  position: relative;

  // Give tile an aspect ratio
  &:before {
    padding-bottom: 100%;
    display: block;
    content: '';
  }

  @include breakpoint(medium) {
    width: 50%;
    margin-bottom: 0;
  }

  @include breakpoint(large) {
    width: 33.333333%;
  }

  // --------------------------
  // This is necessary because the tiles need some padding from .c-tile
  .c-tile-content {
    background: var(
      --gb-brand-primary
    ); // This color should never be visible because every tile needs a color class
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: $gb_white;
    text-decoration: none;
    overflow: hidden;

    &:hover {
      text-decoration: none;
    }

    @include breakpoint(medium) {
      top: 15px;
      right: 15px;
      bottom: 15px;
      left: 15px;
    }

    // Hide arrow
    &::before {
      display: none;
    }

    &__link {
      min-height: 50%;
      overflow: hidden;
      line-height: 0;

      &:hover {
        text-decoration: none;
      }

      &::before {
        content: none;
      }
    }

    &__link--video {
      position: relative;
      width: 50%;

      &::before {
        padding-bottom: 100%;
        display: block;
        content: '';
      }
    }

    &__hero-slide {
      background: no-repeat center;
      background-size: cover;
      width: 100%;
      padding: 40px;
    }

    &__text {
      background: no-repeat center;
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      padding: 40px;
      transition: background-size 0.25s ease;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      h3,
      p,
      .category {
        display: flex;
        flex-direction: column;
      }

      .link {
        color: $gb_white;
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }

      span,
      h3 {
        position: relative;
        z-index: 1;
      }
    }

    .marker {
      width: 3.75rem;
      height: 3.75rem;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    &__image {
      position: relative;
      background: no-repeat center;
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      transition: background-size 0.25s ease;
    }
  }

  // Hover animation for linked tiles
  a,
  div {
    &.c-tile-content {
      transition: box-shadow 0.25s ease;

      &:hover,
      &:focus {
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);

        .c-tile-content__text,
        .c-tile-content__image {
          background-size: 110% 110%;
        }
      }
    }
  }

  // --------------------------
  // Tile types
  &__width-2 {
    &:before {
      padding-bottom: 200%;
    }

    .c-tile-content,
    .c-tpp-area {
      flex-direction: column;
    }

    .c-tile-video {
      .c-tile-content__link {
        height: 50%;

        .c-tile-content__text {
          height: 100%;
        }
        .c-tile-content__image {
          height: 100%;
        }
      }
    }

    .c-tile-content__link {
      height: 100%;

      .c-tile-content__text {
        position: relative;
        height: 50%;
      }

      .c-tile-content__image {
        height: 50%;

        &--video::before {
          content: none;
        }

        &::before {
          padding-bottom: 100%;
          display: block;
          content: '';
        }
      }
    }

    @include breakpoint(medium) {
      width: 100%;

      &:before {
        padding-bottom: 50%;
      }

      .c-tile-content,
      .c-tpp-area {
        flex-direction: row;
      }

      .c-tile-content__link,
      .c-tile-video .c-tile-content__link {
        display: flex;
        width: 100%;
        height: 100%;

        &:hover {
          text-decoration: none;
        }

        &.c-tile-content__text {
          width: 50%;
        }

        .c-tile-content__text {
          height: 100%;
        }

        .c-tile-content__image {
          height: 100%;
        }
      }
    }

    @include breakpoint(large) {
      width: 66.666666%;
    }
  }

  &__height-2,
  &__width-2 {
    .coverimage {
      width: 100%;
      height: 100%;

      .c-tile-content__image {
        width: 100%;
        height: 100%;
        @include aspect-ratio(16, 9);
      }

      .c-tile-content__text {
        position: absolute;
        top: 0;
      }
    }

    .c-tpp-area {
      position: absolute;
      top: 0;
    }
  }

  &__download,
  &__location-service {
    .c-tile-content {
      .category,
      h3 {
        color: var(--gb-brand-primary-light);
      }
    }
  }

  &__download .c-tile-content {
    &:after {
      @include web20-icon('download');
      position: absolute;
      top: 2.5em;
      right: 2.5em;
      font-size: 4rem;
      color: $gb_grey_100;
    }

    h3 {
      margin-right: 65px;
    }
  }

  // --------------------------
  // Tile background colors

  &__height-2 {
    display: flex;
    flex-direction: row;

    &:before {
      padding-bottom: 200%;
    }

    .c-tile-video {
      .c-tile-content__link {
        height: 50%;

        .c-tile-content__text {
          height: 100%;
        }
        .c-tile-content__image {
          height: 100%;
        }
      }
    }

    .c-tile-content,
    .c-tpp-area {
      flex-direction: column;

      &__link {
        height: 100%;
      }

      &__text {
        position: relative;
        height: 50%;
      }
    }

    .c-tile-content__image {
      height: 50%;

      &:before {
        padding-bottom: 100%;
        display: block;
        content: '';
      }
    }

    @include breakpoint(medium) {
      &:before {
        padding-bottom: 200%;
      }

      .c-tile-content__image {
        &--video {
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
        }
      }
    }

    &.c-tile-homepage {
      &--blue-gradient .c-tile-content {
        background: linear-gradient(
          to bottom,
          rgba(var(--gb-brand-primary-light), 0.9) 0%,
          rgba(var(--gb-brand-primary-light), 0.7) $gradient-color-first,
          rgba(var(--gb-brand-primary-light), 0) $gradient-color-last
        );
      }

      &--white-gradient .c-tile-content {
        background: linear-gradient(
          to bottom,
          rgba($gb_grey_060, 0.9) 0%,
          rgba($gb_grey_060, 0.7) $gradient-color-first,
          rgba($gb_grey_060, 0) $gradient-color-last
        );
      }

      &--grey-gradient .c-tile-content {
        background: linear-gradient(
          to bottom,
          rgba($gb_grey_100, 0.9) 0%,
          rgba($gb_grey_100, 0.7) $gradient-color-first,
          rgba($gb_grey_100, 0) $gradient-color-last
        );
      }

      &--dark-grey-gradient .c-tile-content {
        background: linear-gradient(
          to bottom,
          rgba($gb_grey-blue, 0.9) 0%,
          rgba($gb_grey-blue, 0.7) $gradient-color-first,
          rgba($gb_grey-blue, 0) $gradient-color-last
        );
      }

      &--black-gradient .c-tile-content {
        background: linear-gradient(
          to bottom,
          rgba($gb_black, 0.9) 0%,
          rgba($gb_black, 0.7) $gradient-color-first,
          rgba($gb_black, 0) $gradient-color-last
        );
      }
    }
  }

  &--blue {
    .c-tile-content {
      background-color: var(--gb-brand-primary-light);
    }

    &-gradient {
      .c-tile-content {
        background: linear-gradient(
          to right,
          rgba(var(--gb-brand-primary-light), 0.9) 0%,
          rgba(var(--gb-brand-primary-light), 0.7) $gradient-color-first,
          rgba(var(--gb-brand-primary-light), 0) $gradient-color-last
        );
      }
    }
  }

  &--white {
    .c-tile-content {
      background-color: $gb_grey_060;
    }

    &-gradient {
      .c-tile-content {
        background: linear-gradient(
          to right,
          rgba($gb_grey_060, 0.9) 0%,
          rgba($gb_grey_060, 0.7) $gradient-color-first,
          rgba($gb_grey_060, 0) $gradient-color-last
        );
      }
    }
  }

  &--grey {
    .c-tile-content {
      background-color: $gb_grey_100;
    }

    &-gradient {
      .c-tile-content {
        background: linear-gradient(
          to right,
          rgba($gb_grey_100, 0.9) 0%,
          rgba($gb_grey_100, 0.7) $gradient-color-first,
          rgba($gb_grey_100, 0) $gradient-color-last
        );
      }
    }
  }

  &--light-grey {
    .c-tile-content {
      background-color: $gb_grey_060;
    }

    &-gradient {
      .c-tile-content {
        background: linear-gradient(
          to right,
          rgba($gb_grey_060, 0.9) 0%,
          rgba($gb_grey_060, 0.7) $gradient-color-first,
          rgba($gb_grey_060, 0) $gradient-color-last
        );
      }
    }
  }

  &--dark-grey {
    .c-tile-content {
      background-color: $gb_grey-blue;
    }

    &-gradient {
      .c-tile-content {
        background: linear-gradient(
          to right,
          rgba($gb_grey-blue, 0.9) 0%,
          rgba($gb_grey-blue, 0.7) $gradient-color-first,
          rgba($gb_grey-blue, 0) $gradient-color-last
        );
      }
    }
  }

  &--black {
    .c-tile-content {
      background-color: $gb_black;
    }

    &-gradient {
      .c-tile-content {
        background: linear-gradient(
          to right,
          rgba($gb_black, 0.9) 0%,
          rgba($gb_black, 0.7) $gradient-color-first,
          rgba($gb_black, 0) $gradient-color-last
        );
      }
    }
  }

  // --------------------------
  // Tile text colors

  &--white,
  &--grey,
  &--light-grey,
  &--medium-grey {
    .c-tile-content__text {
      .category,
      h3,
      p,
      strong,
      .link {
        color: $gb_black;
      }

      form {
        border-bottom-color: $gb_black;

        input[type='text'],
        input[type='text']::placeholder {
          color: $gb_black;
        }

        button {
          i {
            color: $gb_black;
          }
        }
      }
    }
  }

  &--dark-grey,
  &--blue,
  &--black {
    .c-tile-content__text {
      .category,
      h3,
      p,
      strong,
      .link {
        color: $gb_white;
      }

      form {
        border-bottom-color: $gb_grey_060;

        input[type='text']:not([name='zipOrCity']),
        input[type='text']:not([name='zipOrCity'])::placeholder {
          color: $gb_grey_060;
        }

        button {
          i {
            color: $gb_grey_060;
          }
        }
      }
    }
  }

  // --------------------------
  // Content Creator styling fixes
  .c-tpp-area {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .c-tile-content__background-image > .c-tpp-area {
    display: block;
  }

  // --------------------------
  // Content styling
  .label {
    background: $gb_grey_800;
    margin: 0 0 1.875rem -2.5rem;
    padding: 0.375rem 0.9375rem 0.1875rem 2.5em;
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;

    &--highlight {
      background: $gb_brand-utility-warning;
    }
  }

  .category {
    margin-bottom: 0.9375rem;
    display: block;
    font-size: 0.9375rem;
    line-height: 1.4;
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 1.875rem;
    font-size: 1.875rem;

    b {
      display: block;
    }
  }

  p {
    margin-bottom: 2.5em;
  }

  .link {
    @extend a;
    font-weight: bold;
    text-transform: uppercase;

    &:before,
    &:hover {
      text-decoration: none;
    }
  }

  .form-location {
    padding-right: 40px;
    position: relative;
    border-bottom: $border-black;

    input,
    button {
      background: transparent;
      height: 40px;
      padding: 0;
      box-shadow: none;
      border: 0;
      appearance: none;
    }

    input[type='text']:not([name='zipOrCity']) {
      width: 100%;
      font-size: 0.9375rem;
      font-weight: bold;
      line-height: 2.5em;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        opacity: 1;
        color: $gb_black;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        opacity: 1;
        color: $gb_black;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        opacity: 1;
        color: $gb_black;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        opacity: 1;
        color: $gb_black;
      }
    }

    button[type='submit'] {
      width: 40px;
      overflow: hidden;
      position: absolute;
      right: 0;
      bottom: 0;

      // Screen reader only
      span {
        visibility: hidden;
        font-size: 0;
      }

      &:before {
        @include web20-icon('marker-pin');
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 1.5rem;
        color: var(--gb-brand-primary);
        transform: translate(-50%, -50%);
      }

      &:hover:before {
        color: $gb_black;
      }
    }
  }
}

.c-sticky-header {
  background: $gb_grey_025;
  width: 100%;
  min-height: $sticky-header-height;
  box-shadow: 0 0.5rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.22);
  z-index: $z-sticky-header;
  position: relative;

  &__logo {
    min-width: 8.75rem;
    width: 10rem;
    transform: scale3d(1, 1, 1);
    margin-top: calc-center(60, 20);
  }

  &__group-right {
    display: flex;
    justify-content: flex-end;
    font-size: 1.25rem;
  }

  &__btn-menu {
    margin-top: calc-center(60, 20);
    margin-left: 1.25rem;
    cursor: pointer;

    span {
      text-transform: uppercase;
      color: $gb_grey-blue;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      margin-top: calc-center(60, 14);
    }

    &:hover {
      i::before {
        transform: scaleY(1.3);
      }
    }
  }

  &--animation-appear {
    a,
    button {
      opacity: 0.5;
      transition: all 500ms ease-in-out;
    }
  }

  &--animation-appear.c-sticky-header--animation-appear-active {
    a,
    button {
      opacity: 1;
    }
  }

  &--animation-enter {
    a,
    button {
      opacity: 0.5;
      transition: all 500ms ease-in-out;
    }
  }

  &--animation-enter.c-sticky-header--animation-enter-active {
    a,
    button {
      opacity: 1;
    }
  }
}

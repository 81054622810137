.error-page {
  height: 100vh;
  padding-top: calc-pixel(61);
}

.c-header--error-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  padding-top: 1.375rem;

  .c-logo {
    width: calc-pixel(123);
    height: calc-pixel(17);
    display: block;

    img {
      display: block;
    }

    @include breakpoint(large) {
      width: calc-pixel(160);
      height: auto;
      display: flex;

      img {
        width: calc-pixel(160);
        height: calc-pixel(23);
        vertical-align: middle;
      }
    }
  }
}

.c-error-page {
  background: $gb_grey_060 no-repeat center;
  background-size: cover;
  height: 100%;
  padding: calc-pixel(50) 0 calc-pixel(150) 0;

  span {
    padding-bottom: 1rem;
  }

  .h2 {
    font-weight: $font-weight-bold;
    margin: 0 0 $spacing-s;
  }

  &__content {
    max-width: math.div($grid-container, 2);
  }

  .c-search-bar-form {
    border-top: 0;

    &--grey-bg {
      background-color: transparent;
    }
  }

  .c-search-bar {
    background-color: transparent;
    height: auto;
    padding: 0;

    &__line {
      background-color: $gb_white;
      border-bottom: none;
      margin-left: 0;
      margin-right: 0;
    }

    &__input {
      padding-top: calc-pixel(10);
    }

    &__btn {
      padding: 5px 0.8125rem 0 0;
    }

    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .c-suggestion-flyout {
    top: 0.9375rem;
  }

  .link-list {
    a {
      text-transform: uppercase;
      color: $gb_black;
      font-weight: $font-weight-medium;
      letter-spacing: calc-letter-spacing(80);
    }
  }
}

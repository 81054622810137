@use 'sass:math';

.product-listing-page {
  .c-loader {
    margin: auto;
  }

  .c-product-tiles {
    margin-bottom: 5rem;
  }

  .c-pagination {
    display: block;

    &__bar {
      display: flex;
      flex-basis: 100%;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
      max-width: math.div($gb_containerMaxWidth, 2);

      .left,
      .right {
        height: 2.375rem;
        line-height: 1;
        padding: 10px;
        width: 2.375rem;

        .icon::before {
          color: $gb-white;
        }

        background-color: $gb_grey_100;

        &.is--active {
          background-color: $gb_grey-blue;
        }
      }
    }

    .pagination-right {
      flex-basis: 100%;
      justify-content: flex-end;
    }
  }
}

.loadmore-button {
  margin: 0 auto;
  display: block;
  width: 100%;

  @include breakpoint(medium) {
    width: auto;
  }
}

$header-height: 145px;

.c-landingpage-intro {
  height: calc(
    100vh - #{$header-height}
  ); // 16:9 in the designs, but different behaviour in web 1.0; we use 1.0 here
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  > .c-tpp-area {
    height: 100%;
  }

  &__content--white {
    color: $gb-white;
  }

  &__content--black {
    color: $gb-black;
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 2.25rem;

    @include breakpoint(large) {
      top: 3.75rem;
    }

    h1 {
      font-weight: $font-weight-bold;
      margin-bottom: 0.625rem;
      font-size: 2rem;

      @include breakpoint(medium) {
        font-size: 2.875rem;
      }

      @include breakpoint(large) {
        font-size: 3.25rem;
      }
    }

    h2 {
      font-weight: $font-weight-light;
      font-size: 1rem;

      @include breakpoint(medium) {
        font-size: 1.5rem;
      }

      @include breakpoint(large) {
        font-size: 1.625rem;
      }
    }
  }

  .c-landingpage-intro__content--bottom-right,
  .c-landingpage-intro__content--bottom-left {
    bottom: 0;
    top: inherit;
  }

  .c-landingpage-intro__content--top-right,
  .c-landingpage-intro__content--bottom-right {
    text-align: right;
  }
}

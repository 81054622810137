//
// Table of content styling
// ----------------------
#sticky-toc {
  .c-table-of-content {
    margin: 0;
    min-height: 3rem;

    &--wrapper {
      padding-left: 0;
      padding-right: 0;
      max-width: inherit;
      margin: 0;
      width: 75%;

      .dropdown {
        &__trigger {
          min-height: 3rem;
        }
      }
    }
  }
}

.c-table-of-content {
  position: relative;
  margin: 0 0 $spacing-xl;
  max-width: 100%;

  .dropdown__trigger {
    background: $gb_grey-blue;
    padding: 0.75rem 0.9375rem 0.625rem 0.9375rem;
    display: flex;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    box-shadow: none;

    .icon {
      margin-top: 5px;
      padding-right: 0.625rem;
      position: relative;
      text-decoration: none;

      &::before {
        color: $gb_white;
        font-size: 1rem;
      }
    }

    &::before {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-position: inside;
      max-width: 97%;

      li {
        display: none;

        &.is--active {
          display: flex;
          padding-bottom: 0;
          font-size: 0.75rem;
          font-weight: $font-weight-bold;
          height: 24px;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .dropdown__content {
    background: $gb_white;
    box-shadow: $default-box-shadow;
    min-width: 18.75rem;
    padding: 0.625rem 0;
    width: 100%;
    z-index: $z-dropdown;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding: 0;

      &::before {
        display: none;
      }

      &:hover {
        font-weight: $font-weight-bold;
        color: var(--gb-brand-primary-light);
      }
    }

    a {
      max-height: 2.58rem;
      padding: 6px 0.9375rem;
      display: block;
      overflow-y: hidden;
      font-size: 0.8125rem;
      color: $gb_grey-blue;
      line-height: 1rem;
      text-decoration: none;

      &::before {
        display: none;
      }

      &:hover {
        font-weight: $font-weight-bold;
        color: var(--gb-brand-primary-light);
      }
    }

    li.is--active a {
      font-weight: $font-weight-bold;
      color: var(--gb-brand-primary-light);
    }
  }

  &.dropdown--active {
    .dropdown__trigger {
      background: $gb_white;
      color: var(--gb-brand-primary-light);

      .icon::before {
        color: var(--gb-brand-primary-light);
        transform: rotate(180deg);
      }
    }
  }
}

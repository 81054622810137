.c-progress-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__placeholder {
    align-self: flex-end;
    padding: 9px 1rem;
  }

  &__dot {
    background: $gb_grey_400;
    width: 1.875rem;
    height: 3px;
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-of-type {
      padding-right: 0;
    }

    &.active {
      background: var(--gb-brand-primary);
    }
  }

  &__number {
    color: var(--gb-brand-primary);
    float: left;
    padding: 8px 1rem;
    text-decoration: none;
    transition: background-color 0.3s;
    font-weight: $font-weight-bold;

    &::before {
      display: none;
    }

    &.active {
      color: $gb-black;
    }

    &:hover:not(.active) {
      color: $gb-black;
    }

    &.left,
    &.right {
      height: 2.375rem;
      line-height: 1;
      padding: 10px;
      width: 2.375rem;

      .icon::before {
        color: $gb-white;
      }
    }

    &.left {
      background-color: $gb_grey_100;
    }

    &.right {
      background-color: $gb_grey-blue;
    }
  }
}

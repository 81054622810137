a {
  &.button {
    color: $gb_white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.button {
  position: relative;
  font-size: 0.8125rem;
  line-height: 1.3;
  font-weight: $font-weight-bold;
  letter-spacing: 0.04375rem;
  border: 1px solid $body-font-color;
  padding: 10px 1.875rem;
  cursor: pointer;
  transition: all 0.4s ease-out;

  i {
    margin-right: 10px;

    &::before {
      bottom: 1px;
      font-size: 0.8125rem;
      position: relative;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    border-color: $gb_grey_150;
    color: $gb_grey_150;
    cursor: inherit;
  }

  /**
  * Button alignment
  */

  &--center {
    width: 100%;
    text-align: center;
    padding: calc-center(37, 12) 1.5625rem 0.625rem 1.5625rem;

    i::before {
      position: relative;
      left: 0;
      top: 0;
    }
  }

  /**
   * Button types
   */
  &--type-seamless {
    border-color: transparent !important;
  }

  &--type-link {
    @extend .button--type-seamless;
    padding: 0;
    font-weight: 500;
    text-transform: initial;
  }

  &--close {
    padding: 0;

    i::before {
      font-size: 1.125rem;
    }
  }

  /**
   * Button colors
   */
  &--black {
    border-color: $gb_black;
    color: $gb_black;

    &:hover {
      background-color: $gb_black;
      color: $gb_white;
    }

    &--disabled,
    &:disabled {
      background-color: inherit;
      border-color: $gb_grey_150;
      color: $gb_grey_150;
    }
  }

  &--white {
    border-color: $gb_white;
    color: $gb_white;

    &:hover {
      background-color: $gb_white;
      color: $gb_black;
    }

    &--disabled,
    &:disabled {
      background-color: inherit;
      border-color: $gb_grey_150;
      color: $gb_grey_150;
    }
  }

  &--primary {
    border-color: var(--gb-brand-primary);
    color: var(--gb-brand-primary);

    &--disabled {
      color: var(--gb-brand-primary);
      opacity: 0.7;
    }
  }

  &--highlight {
    border-color: $gb_brand-utility-warning;
    color: $gb_brand-utility-warning;

    &:hover {
      background-color: $gb_brand-utility-warning;
      color: $gb_white;
    }

    &--disabled,
    &:disabled {
      background-color: inherit;
      border-color: $gb_grey_150;
      color: $gb_grey_150;
    }
  }

  &--primary-light {
    border-color: var(--gb-brand-primary-dark);
    color: var(--gb-brand-primary-dark);

    &--disabled {
      color: var(--gb-brand-primary-dark);
      opacity: 0.7;
    }
  }

  &--primary-dark {
    border-color: var(--gb-brand-primary-light);
    color: var(--gb-brand-primary-light);

    &--disabled {
      color: var(--gb-brand-primary-light);
      opacity: 0.7;
    }
  }

  &--grey-blue {
    border-color: $gb_grey-blue;
    color: $gb_grey-blue;

    &--disabled {
      color: $gb_grey-blue;
      opacity: 0.7;
    }
  }

  &--grey-025 {
    border-color: $gb_grey_025;
    color: $gb_grey_025;

    &--disabled {
      color: $gb_grey_025;
      opacity: 0.7;
    }
  }

  &--grey-050 {
    border-color: $gb_grey_060;
    color: $gb_grey_060;

    &--disabled {
      color: $gb_grey_060;
      opacity: 0.7;
    }
  }

  &--grey-075 {
    border-color: $gb_grey_075;
    color: $gb_grey_075;

    &--disabled {
      color: $gb_grey_075;
      opacity: 0.7;
    }
  }

  &--grey-100 {
    border-color: $gb_grey_100;
    color: $gb_grey_100;

    &--disabled {
      color: $gb_grey_100;
      opacity: 0.7;
    }
  }

  &--grey-400 {
    border-color: $gb_grey_400;
    color: $gb_grey_400;

    &--disabled {
      color: $gb_grey_400;
      opacity: 0.7;
    }
  }

  &--grey-650 {
    border-color: $gb_grey_650;
    color: $gb_grey_650;

    &--disabled {
      color: $gb_grey_650;
      opacity: 0.7;
    }
  }

  /**
   * Button background colors
   */
  &--background-primary {
    border-color: var(--gb-brand-primary);
    background: var(--gb-brand-primary);

    &:hover {
      border-color: var(--gb-brand-primary-light);
      background: var(--gb-brand-primary-light);
      color: $gb_white;
    }

    &--disabled,
    &:disabled {
      background: $gb_grey_150;
      border-color: $gb_grey_150;
      color: $gb_white;
    }
  }

  &--background-primary-dark {
    border-color: var(--gb-brand-primary-light);
    background: var(--gb-brand-primary-light);

    &:hover {
      border-color: var(--gb-brand-primary-light);
      background: var(--gb-brand-primary-light);
      color: $gb_white;
    }

    &--disabled {
      background: var(--gb-brand-primary-light);
      opacity: 0.7;
    }
  }

  &--background-grey-blue {
    border-color: $gb_grey-blue;
    background: $gb_grey-blue;

    &:hover {
      border-color: $gb_grey-blue;
      background: $gb_grey-blue;
      color: $gb_white;
    }

    &--disabled {
      background: $gb_grey-blue;
      opacity: 0.7;
    }
  }

  &--background-positive-green {
    border-color: $gb_positive_green;
    background: $gb_positive_green;

    &:hover {
      border-color: $gb_positive_green;
      background: $gb_positive_green;
      color: $gb_white;
    }

    &--disabled {
      background: $gb_positive_green;
    }
  }
}

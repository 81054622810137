.gigya-screen-dialog.gigya-style-modern #gigya-screen-dialog-page-overlay {
  background: $gb_black !important;
  opacity: 0.5 !important;
}

.gigya-screen-dialog-mobile {
  &.gigya-style-modern #gigya-screen-dialog-page-overlay {
    background: $gb_white;
    opacity: 1;
  }

  &.gigya-style-modern {
    .gigya-screen {
      width: 100%;
    }

    .gigya-screen-dialog-top {
      overflow: visible;
    }
  }
}

.gigya-style-modern div.gigya-screen-dialog-main,
.gigya-screen-dialog {
  &:focus {
    outline: none;
  }

  input {
    pointer-events: all;
    cursor: pointer;
  }
}

.gigya-screen-dialog,
.gigya-screen-dialog-mobile {
  a {
    &:hover {
      text-decoration: none;
    }

    &::before {
      display: none;
    }
  }

  .gigya-screen-dialog-top {
    height: 1rem;

    .gigya-screen-dialog-close {
      width: 2rem;
      align-items: flex-end;
      justify-content: flex-end;
      display: flex;
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;

      a {
        &::before {
          @extend .web20-icon;
          @extend .web20-icon-close;
          font-size: 1rem;
        }

        img {
          display: none;
        }
      }
    }
  }

  &-main {
    .gigya-screen-dialog-mobile-top {
      .gigya-screen-dialog-mobile-close {
        margin-top: 0.625rem;

        a {
          display: block;

          img {
            margin-top: 0;
            width: 1.25rem;
          }
        }
      }
    }
  }

  input[type='checkbox'] {
    opacity: initial;
    top: initial;
    left: initial;
    position: relative;
    margin-top: 0.5rem;
  }

  .gigya-screen {
    .gigya-composite-control.gigya-composite-control-multi-choice .gigya-multi-choice-item label {
      margin-left: 0;
      box-sizing: border-box;
    }

    .gigya-composite-control.gigya-composite-control-label.gig-consent-register-nosc {
      pointer-events: none;

      label:not(.gigya-hidden) {
        pointer-events: all;
      }
    }

    .gigya-composite-control.gigya-terms-error,
    .gigya-terms-error {
      box-sizing: border-box;
    }

    .gigya-multi-choice-item {
      margin-bottom: 0.625rem;

      label {
        cursor: pointer;
        padding: 10px !important;

        label {
          pointer-events: none;
          padding: 0 !important;
        }
      }

      input[type='radio']:checked {
        + label {
          background: $gb_grey_100;
        }
      }
    }

    .ic-arrow-item::before {
      @extend .web20-icon-arrow-link-light-right;
    }
  }
}

.gigya-update-profile-screen {
  h2 {
    text-transform: none;
  }

  .gigya-composite-control.gigya-composite-control-checkbox.geberit-switch input + label:before {
    box-sizing: border-box;
  }
}

h2.gigya-composite-control {
  text-transform: none;
}

#GIGYAContainer .gigya-screen .ic-arrow-item::before {
  font-family: 'geberitWeb20IconFont', sans-serif !important;
}

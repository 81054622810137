$image-gallery-offset-top: 3.75rem;
$arrow-button-size: 2.5rem;

.c-lightbox {
  -webkit-overflow-scrolling: touch;
  outline: none;
  background: transparent;
  max-width: $grid-container;
  max-height: 100%;
  display: inline-block;
  overflow-y: auto;
  height: 100%;
  width: 100vw;

  @include breakpoint(medium) {
    height: calc(100% - 120px); // 60px margin top and bottom
    margin: $image-gallery-offset-top $arrow-button-size 0;
    max-width: 43.75rem;
  }

  &__locator {
    height: auto;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $gb_white;
    padding: $spacing-xs 1.5625rem 1.5625rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
    min-height: 100vh;

    @include breakpoint(medium) {
      height: auto;
      min-height: auto;
      width: auto;
      padding: $spacing-xs $spacing-m 3.125rem;
      display: block;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $backdrop-color;
    z-index: $z-lightbox;
    text-align: center;
  }

  &__video-title,
  &__title {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint(large) {
      flex-direction: row;
    }

    h4,
    h3 {
      text-align: left;

      @include breakpoint(large) {
        padding-right: 3.125rem;
        flex-grow: 1;
      }
    }

    button {
      text-align: right;

      @include breakpoint(large) {
        align-self: flex-start;
      }
    }
  }

  &__close {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: var(--gb-brand-primary);
    margin-bottom: 0.9375rem;
    align-self: flex-end;
    font-size: 0.75rem;

    span {
      display: none;
      min-height: 0;

      @include breakpoint(medium) {
        display: inline-block;
      }
    }

    @include breakpoint(large) {
      margin-bottom: $spacing-m;
    }

    i {
      margin-right: 0.625rem;
      font-size: 1rem;
    }
  }

  &__video-container {
    position: relative;

    @include breakpoint(medium) {
      position: relative;
    }

    .mi-controls.controls {
      width: 100% !important;
    }
  }

  &__video-description {
    display: flex;
    margin-top: $spacing-xs;

    span:first-of-type {
      flex-grow: 1;
      text-align: left;
    }
  }
}

$arrow-button-size: 2.5em;

.c-image-gallery {
  border-bottom: $border-black;
  margin-bottom: $section-margin;
  padding-bottom: $section-padding;

  @include breakpoint(medium) {
    display: flex;
    flex-flow: row wrap;
    margin-left: -0.9375rem; // because of padding 15px from c-image-gallery__item
    margin-right: -0.9375rem;
    flex-basis: auto;
  }

  &__headline {
    margin-bottom: 0;
    margin-right: 1.25rem;
  }

  &--disable-border {
    border: none;
    padding-bottom: 0;
  }

  .button {
    width: 100%;

    i {
      &::before {
        font-size: 1.25rem;
      }
    }
  }

  &--4-16_9 {
    .c-image-gallery__item,
    .c-tiles__column {
      padding: 0 0.9375rem 2.25rem;

      @include breakpoint(medium) {
        width: 50%;
      }

      @include breakpoint(large) {
        width: 25%;
      }
    }

    .c-tiles__column {
      @extend .cell;
      @extend .medium-6;
      @extend .large-4;
      background: 0 none;
      margin-left: 0;
    }
  }

  &--4-1_1 {
    .c-image-gallery__item,
    .c-tiles__column {
      padding-bottom: 2.25rem;

      @include breakpoint(medium) {
        width: 33%;
        padding: 0 0.9375rem 2.25rem;
      }

      @include breakpoint(large) {
        width: 25%;
      }
    }

    .c-tiles__column {
      @extend .cell;
      @extend .medium-6;
      @extend .large-4;
      background: 0 none;
      margin-left: 0;
    }
  }

  &--3-16_9 {
    .c-image-gallery__item,
    .c-tiles__column {
      padding: 0 0.9375rem 2.25rem;

      @include breakpoint(medium) {
        width: 50%;
      }

      @include breakpoint(large) {
        width: 33%;
      }
    }

    .c-tiles__column {
      @extend .cell;
      @extend .medium-6;
      @extend .large-3;
      background: 0 none;
      margin-left: 0;
    }
  }

  &--3-1_1 {
    .c-image-gallery__item,
    .c-tiles__column {
      padding: 0 0.9375rem 2.25rem;

      @include breakpoint(medium) {
        width: 33%;
      }
    }

    .c-tiles__column {
      @extend .cell;
      @extend .medium-6;
      @extend .large-3;
      background: 0 none;
      margin-left: 0;
    }
  }

  &__item {
    align-self: flex-start;
    margin-left: 0;

    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      text-decoration: none;
    }

    &::before {
      content: none;
    }

    &-container {
      height: 100vh;
      padding-top: 1.6875rem;
      position: static;
      text-align: left;
      width: 100%;

      @include breakpoint(medium) {
        height: auto;
      }

      .image--aspect-ratio {
        margin-bottom: 1.125rem;
      }

      .c-lightbox__close {
        font-weight: $font-weight-bold;
        margin-left: auto;
        margin-bottom: 0;
      }

      .c-image-gallery__content {
        width: 100% !important;
        @include bullet-points-spacing();

        span {
          font-weight: bold;
          display: block;
        }
      }

      .c-image-gallery__links {
        width: 100% !important;

        a {
          padding-bottom: 0.625rem;
        }
      }

      &__header {
        display: flex;
        margin-bottom: 1.25rem;
      }
    }
  }

  &__content {
    @include hyphens();
    text-align: left;

    p:last-child {
      margin-bottom: 1.875rem;
    }
  }

  &__links {
    text-align: left;

    a {
      @include hyphens();
      display: block;
      padding-bottom: 0.625rem;
      hyphens: auto;
      word-break: break-all;
    }
  }

  &__img-description {
    @include word-wrap();
    color: $gb_grey-blue;
    padding-top: 1.25rem;
    text-align: left;
    cursor: default;
  }

  &__arrow {
    align-items: center;
    background: rgba($gb_black, 0.3);
    bottom: 0;
    display: flex;
    height: $arrow-button-size;
    justify-content: center;
    position: absolute;
    top: calc(50% - (40px / 2));
    width: $arrow-button-size;
    z-index: 1;
    left: 0;

    @include breakpoint(large) {
      left: $arrow-button-size;
    }

    i {
      &::before {
        color: $gb_white;
        font-size: 1rem;
      }
    }

    &--next {
      left: auto;
      right: 0;

      @include breakpoint(large) {
        left: auto;
        right: $arrow-button-size;
      }
    }
  }

  &__img-wrapper {
    cursor: pointer;
    display: block;
    position: relative;

    &::before {
      display: none;
    }

    .web20-icon-lens-zoom-in {
      bottom: 1.25rem;
      font-size: 2rem;
      position: absolute;
      right: 1.25rem;
      text-shadow: 0 0.1875rem 1.5625rem rgba(0, 0, 0, 0.2);
    }

    .image-content {
      img {
        width: 100%;
      }
    }
  }
}

// Just text
.c-landingpage-text {
  background-color: $gb_grey-blue;
  color: $gb-white;
  padding: 3.375rem 0 6.5625rem;
  margin-bottom: $section-margin;

  &--blue-grey {
    background-color: $gb_grey-blue;
    color: $gb-white;

    a {
      color: $gb_white;
    }
  }

  &--light-grey {
    background-color: $gb_grey_050;
    color: $gb_black;

    a {
      color: $gb_black;
    }
  }

  &--medium-grey {
    background-color: $gb_grey_100;
    color: $gb_black;

    a {
      color: $gb_black;
    }
  }

  &--white {
    background-color: $gb_white;
    color: $gb_black;

    a {
      color: $gb_black;
    }
  }

  h2 {
    font-weight: $font-weight-bold;
    margin-bottom: 7px;

    @include breakpoint(large) {
      max-width: 66%;
    }
  }

  h3 {
    margin-bottom: 1.4375rem;

    @include breakpoint(large) {
      font-size: 1.625rem;
      max-width: 66%;
    }
  }

  a {
    @include word-wrap();

    &::before {
      font-size: 0.8125rem;
      margin-right: 0;
    }
  }
}

// Text with image
.c-landingpage-text-image {
  background: $gb_black no-repeat center;
  background-size: cover;
  padding: 3.125rem 0;
  color: $gb-white;
}

.c-toolembed-lightbox {
  margin: 0;
  position: relative;

  .c-lightbox__close {
    position: absolute;
    top: 1.6875rem;
    right: 1.875rem;
    z-index: 20;
  }

  .c-lightbox__container {
    height: 100%;
    padding: 4.25rem 1.875rem 1.875rem 1.875rem;
  }
}

.c-toolembed {
  height: inherit;

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .c-loader {
      position: absolute;
    }
  }

  .c-lightbox {
    width: 100%;
    height: 100%;

    @include breakpoint(medium) {
      width: 100%;
      height: 100%;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

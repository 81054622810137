.interactive-description {
  border-bottom: $border-black;
  margin-bottom: $section-margin;
  padding-bottom: $section-padding;

  &--disable-border {
    border: none;
    padding-bottom: 0;
  }

  &__wrapper {
    background-color: $gb_grey_060;
    padding: 0.9375rem 0.9375rem 1.875rem;
    position: relative;
    width: 100%;

    @include breakpoint(large) {
      background-color: $gb_white;
      padding: 0;
      position: relative;
    }
  }

  .interactive-description__image-area {
    left: 0;
    position: absolute;

    .interactive-description__hotspot {
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .interactive-description__image-wrapper {
    position: relative;

    .interactive-description__image,
    .interactive-description__image-area {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .interactive-description__info {
    @include word-wrap();
    color: $gb_grey-blue;
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;
    text-transform: uppercase;

    @include breakpoint(medium) {
      display: block;
    }

    @include breakpoint(large) {
      max-width: 80%;
      position: absolute;
      padding-left: 2.1875rem;
      bottom: 0.9375rem;
    }

    > button {
      color: unset;
    }

    i {
      font-size: 1.5rem;
      margin-right: 0.625rem;

      @include breakpoint(large) {
        position: absolute;
        left: 0;
        top: 1.25rem;
      }
    }

    span {
      font-size: 0.875rem;
      position: absolute;
      max-width: 80%;

      @include breakpoint(medium) {
        max-width: 100%;
        position: relative;
      }
    }
  }

  & .interactive-description__content {
    display: block;
    position: relative;

    @include breakpoint(medium) {
      position: static;
    }

    @include breakpoint(large) {
      background-color: $gb_grey_060;
      padding: 1.5625rem 4.375rem 4.0625rem;
      position: relative;
    }

    h4,
    .h4 {
      color: $gb_grey_650;
      overflow: hidden;
    }

    > h5,
    > .h5 {
      color: $gb_grey_650;
      overflow: hidden;
      margin: 0 0 1.9375rem;
      font-size: 1.125rem;
      font-weight: var(--h4-weight);
      letter-spacing: 0.02em;
    }

    .c-tiles__column {
      background-color: $gb_white;
      border: 1px solid $gb_white;
      box-shadow: 0 0 0.9375rem $gb_grey_400;
      min-height: 27.1875rem;
      position: absolute;
      top: 1.5625rem;
      width: 100%;
      z-index: 1;

      @include breakpoint(medium) {
        min-height: 12.5rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 26.875rem;
      }

      @include breakpoint(large) {
        position: absolute;
        top: 12%;
        left: auto;
        margin-bottom: 0;
        transform: none;
        width: 72%;
      }

      button {
        font-size: 1.125rem;
        padding: 0.625rem;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .c-tiles__content {
      background-color: $gb_white;
      padding: 1rem 1.25rem 3rem;
      position: relative;

      & .tileWithoutImage {
        max-width: 90%;
      }

      @include breakpoint(medium) {
        padding: 1rem 1.25rem 3rem;
      }

      &::before {
        @include breakpoint(large) {
          border-top: 0.625rem solid transparent;
          border-bottom: 0.625rem solid transparent;
          border-right: 0.625rem solid $gb_white;
          content: '';
          height: 0;
          left: -0.625rem;
          position: absolute;
          width: 0;
        }
      }

      a {
        display: block;
      }

      h4,
      h5 {
        color: $gb_black;
        margin-bottom: 1rem;
        max-width: 90%;
        font-size: 1.125rem;
        line-height: 1.375rem;
        letter-spacing: 0.02em;
      }

      h4 + p,
      h5 + p {
        margin-top: 0;
      }
    }
  }
}

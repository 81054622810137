.link-button {
  @extend .button;

  &::before {
    content: none;
  }

  &:hover {
    text-decoration: none;
  }

  i::before {
    font-size: 1.1875rem;
    position: absolute;
    left: 1.5625rem;
  }

  &--transparent {
    background: transparent;

    &--white {
      border-color: $gb_white;
      color: $gb_white !important;

      i {
        color: $gb_white;
      }
    }

    &--black {
      border-color: $gb_black;
      color: $gb_black;

      i {
        color: $gb_black;
      }
    }
  }

  &--white {
    background: $gb_white;
    border-color: $gb_white;

    &--brand-primary {
      color: var(--gb-brand-primary) !important;

      i {
        color: var(--gb-brand-primary);
      }
    }
  }
}

//
// Default styling from react-simple-dropdown
// ----------------------
.dropdown--active .dropdown__content {
  display: block;
}

// Table of content styling
// ----------------------
.c-table-of-content {
  position: relative;
  margin: 0 0 1.875rem;

  &.dropdown {
    display: inline-block;
  }

  .dropdown__trigger {
    background: $gb_grey-blue;
    padding: 0.75rem 0.9375rem 0.625rem 0.9375rem;
    display: flex;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
    color: $gb_white;
    text-decoration: none;
    text-transform: uppercase;
    box-shadow: $default-box-shadow;

    &::after {
      display: none;
    }

    .icon {
      margin-top: 5px;
      padding-right: 0.625rem;
      position: relative;
      text-decoration: none;

      &::before {
        color: $gb_white;
        font-size: 1rem;
      }
    }

    &::before {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: none;

        &.is--active {
          display: flex;
          padding-bottom: 0;
          font-size: 0.75rem;
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  .dropdown__content {
    box-shadow: $default-box-shadow;
    min-width: 18.75rem;
    padding: 0.625rem 0;
    width: 100%;
    z-index: $z-dropdown;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding: 0;
      text-transform: uppercase;

      &::before {
        display: none;
      }

      &:hover {
        font-weight: $font-weight-bold;
        color: var(--gb-brand-primary-light);
      }
    }

    span,
    a {
      padding: 6px 0.9375rem;
      display: block;
      font-size: 0.8125rem;
      color: $gb_grey-blue;
      line-height: 1rem;
      text-decoration: none;

      &::before {
        display: none;
      }

      &:hover {
        background-color: $gb_white;
        font-weight: $font-weight-bold;
        color: var(--gb-brand-primary-light);
      }
    }

    li.is--active a {
      font-weight: $font-weight-bold;
      color: var(--gb-brand-primary-light);
    }
  }

  &.dropdown--active {
    .dropdown__trigger {
      background: $gb_white;
      color: var(--gb-brand-primary-light);

      .icon::before {
        color: var(--gb-brand-primary-light);
        transform: rotate(180deg);
      }
    }
  }
}

// Styled selects
// ----------------------
.dropdown {
  display: block;
  position: relative;

  &--active {
    .dropdown__trigger {
      color: $gb_black;
    }
  }

  &__trigger {
    background: $gb_grey_055;
    padding: 0.6875rem 3.125rem 0.6875rem 1.375rem;
    display: block;
    cursor: pointer;
    position: relative;
    color: $gb_black;
    text-align: left;
    text-decoration: none;
    border: 1px solid $gb_grey_200;

    &:hover {
      text-decoration: none;
    }

    &::before {
      display: none;
    }

    &::after {
      pointer-events: none;
      position: absolute;
      top: 1.0625rem;
      right: 1.375rem;
      content: '\f11f';
      @extend .web20-icon;
    }
  }

  &__content {
    // Default styling from react-simple-dropdown
    display: none;
    position: absolute;
    
    background-color: $gb_white;
    width: 100%;
    z-index: $z-dropdown;
    box-shadow: $box-shadow-dropdown;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      padding: 0;
    }

    > span,
    a {
      padding: 0.3125rem 1.375rem 0.375rem 1.375rem;
      display: block;
      color: $gb_black;
      text-align: left;
      text-decoration: none;

      &::before {
        display: none;
      }

      &:hover {
        background-color: $gb_grey_060;
        color: var(--gb-brand-primary);
      }
    }

    &--selected {
      background: $gb_grey_060;

      a {
        color: var(--gb-brand-primary);
      }
    }

    .dropdown__title {
      padding: 0.3125rem 1.375rem 0.375rem 1.375rem;
      display: block;
      color: $gb_black;
      text-align: left;
      text-decoration: none;
    }

    .form-items--radio-list {
      span::before,
      span::after {
        content: none;
      }
    }

    .form-items--radio-list span {
      margin: 0;
      padding: 0.625rem 1.25rem;

      &:hover {
        background-color: $gb_grey_060;
        color: var(--gb-brand-primary-light);
      }
    }
  }

  &--active .dropdown__trigger:after {
    transform: rotate(180deg);
  }
}

.c-loader {
  &--small {
    width: 2rem;
    height: 2rem;
  }

  &--medium {
    width: 4rem;
    height: 4rem;
  }

  &--large {
    width: 8rem;
    height: 8rem;
  }

  &--color-white {
    span {
      animation: loading-fade-white 1s linear infinite;
    }
  }

  span {
    display: inline-block;
    background: transparent;
    width: 25%;
    height: 25%;
    margin: 4%;
    animation: loading-fade-black 1s linear infinite;

    &:nth-child(5) {
      background: transparent;
      animation: none;
    }

    &:nth-child(1) {
      animation-delay: -0.875s;
    }

    &:nth-child(2) {
      animation-delay: -0.75s;
    }

    &:nth-child(3) {
      animation-delay: -0.625s;
    }

    &:nth-child(6) {
      animation-delay: -0.5s;
    }

    &:nth-child(9) {
      animation-delay: -0.375s;
    }

    &:nth-child(8) {
      animation-delay: -0.25s;
    }

    &:nth-child(7) {
      animation-delay: -0.125s;
    }
    @keyframes loading-fade-black {
      0%,
      12.5% {
        background: rgba($gb-black, 0.5);
      }

      20%,
      100% {
        background: transparent;
      }
    }
    @keyframes loading-fade-white {
      0%,
      12.5% {
        background: rgba($gb-white, 0.5);
      }

      20%,
      100% {
        background: transparent;
      }
    }
  }
}

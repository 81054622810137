// Main Menu
// ----------------------------- //

// Variable
// -----------------------------
$menu-mobile-font-family: $body-font-family;
$menu-mobile-font-size: 1rem; // 16px;
$menu-mobile-line-height: 1.2; // 18px
$menu-mobile-color: var(--gb-brand-primary);
$menu-mobile-border: 1px solid #d9d9d9;

// next link
$menu-mobile-next-font-family: $body-font-family;
$menu-mobile-next-font-size: 1rem; // 16px;
$menu-mobile-next-line-height: 1.2; // 18px
$menu-mobile-next-color: $gb_grey_800;

// back link
$menu-mobile-back-font-family: $body-font-family;
$menu-mobile-back-font-size: 0.875rem; // 14px;
$menu-mobile-back-line-height: 1.142857142857143; // 16px
$menu-mobile-back-color: $gb_grey-blue;
$menu-mobile-back-icon-color: $gb_grey-blue;

// main link
$menu-mobile-main-font-family: $body-font-family;
$menu-mobile-main-font-size: 1.125rem; // 18px;
$menu-mobile-main-line-height: 1.22222222222222; // 22px
$menu-mobile-main-color: var(--gb-brand-primary);

// colors
$menu-mobile-background: $gb_white;
$menu-mobile-list-border: 1px solid $gb_grey_060;
$menu-mobile-icon-color-next: var(--gb-brand-primary);
$menu-mobile-color-back: $gb_grey-blue;
$menu-mobile-icon-color-back: $gb_grey-blue;

// grid + width
$menu-mobile-grid-gutter-small: 15px;
$menu-mobile-grid-gutter-medium: 15px;
$menu-mobile-grid-gutter-large: 30px;
$menu-mobile-min-width: 320px;

// Style
// -----------------------------
.main-navigation-mobile {
  overflow: hidden;
  min-width: $menu-mobile-min-width;
  background: $menu-mobile-background;

  li.is-active > .sub-menu {
    display: block;
    animation: navigation-slide-in-sub-menu 0.5s;
  }

  li.is-returned > .sub-menu {
    display: block;
    animation: navigation-slide-out-sub-menu 0.5s forwards;
  }

  .is--deepest > .sub-menu {
    overflow-y: auto;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul[data-level='1'],
  .sub-menu {
    position: fixed;
    top: 7.5625rem;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $menu-mobile-grid-gutter-small;
    background: $menu-mobile-background;
    overflow: hidden;
    height: calc(100vh - 7.5625rem);
    @include breakpoint(medium) {
      padding: $menu-mobile-grid-gutter-medium;
    }

    li {
      border-bottom: $menu-mobile-border;
      padding: 0;

      &:last-child {
        border: none;
      }
    }

    a.ocb {
      &::before {
        content: none;
      }

      span {
        color: $gb_white;
        text-align: center;
        font-size: 0.9375rem;
        padding: 0;
      }
    }

    a,
    span,
    button {
      font-size: $menu-mobile-font-size;
      line-height: $menu-mobile-line-height;
      font-family: $menu-mobile-font-family;
      position: relative;
      display: block;
      padding: 15px 20px 15px 0;
      padding-right: 20px;
      text-decoration: none;
      width: 100%;
      text-align: left;

      &::before {
        content: '';
        padding: 0;
        margin: 0;
      }
    }

    .button--ciam {
      &::before {
        content: none;
      }

      margin-bottom: 1rem;
      margin-right: 0.625rem;
      margin-left: auto;
      color: $gb_white;
      text-align: center;
      font-size: 0.9375rem;

      span {
        color: $gb_white;
        text-align: center;
        font-size: 0.9375rem;
      }
    }

    span {
      cursor: default;
      color: $menu-mobile-next-color;
    }

    a,
    button {
      cursor: pointer;
      color: $menu-mobile-color;

      &::after:hover,
      &:hover {
        text-decoration: none;
      }
    }

    // all links with child elements
    .next {
      color: $menu-mobile-next-color;

      &::after {
        @include web20-icon('chevron-large-right');
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $menu-mobile-icon-color-next;
      }
    }
  }

  .sub-menu {
    display: none;
    z-index: 2;
    width: 100%;

    // back button
    .back {
      font-size: $menu-mobile-back-font-size;
      line-height: $menu-mobile-back-line-height;
      font-family: $menu-mobile-back-font-family;
      color: $menu-mobile-back-color;
      padding: 15px 0 15px 25px;
      position: relative;
      display: block;

      &::before {
        @include web20-icon('back');
        color: $menu-mobile-back-icon-color;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }

    // main link on sub-menu
    a.main-link,
    span.main-link {
      font-size: $menu-mobile-main-font-size;
      line-height: $menu-mobile-main-line-height;
      font-family: $menu-mobile-main-font-family;
      display: block;
      padding: 15px 0;
      border-bottom: $menu-mobile-border;
    }

    span.main-link {
      cursor: default;
      color: $menu-mobile-next-color;
    }

    a.main-link {
      text-decoration: underline;
      color: $menu-mobile-main-color;
      cursor: pointer;
    }

    // multicolumn behaviour
    &--multicolumn {
      > ul {
        > li {
          &:not(:last-child) {
            margin-bottom: 1.875rem;
          }

          > .sub-menu {
            position: static;
            display: block;
            height: auto;
            padding: 0;
            animation: none;

            > .back,
            > .main-link {
              display: none;
            }
          }
        }
      }
    }
  }
}

@keyframes navigation-slide-in-sub-menu {
  0% {
    transform: translate(100vw, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes navigation-slide-out-sub-menu {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100vw, 0);
  }
}

.country-switch {
  .country-switch-accordion {
    > ul {
      padding: 0;
      margin-top: 0;
      column-gap: 30px;
      columns: 1;

      @include breakpoint(medium) {
        columns: 3;
      }

      @include breakpoint(large) {
        columns: 4;
      }

      > li {
        padding: 0;
      }

      a {
        padding: 0.6875rem 0;
        color: $gb_black;
        font-size: 0.9375rem;
        line-height: 1.2;

        &.full-size {
          width: 100%;
        }

        // state
        &:focus,
        &:hover {
          text-decoration: none;
          color: var(--gb-brand-primary);
        }
      }

      li {
        display: flex;
        break-inside: avoid;
        align-items: baseline;

        a {
          color: $gb_black;
        }

        .country-switch-languages {
          display: flex;
          flex-direction: row;
          flex-shrink: 0;
          padding-left: 0.325rem;
          margin: 0;

          li.language-item {
            position: relative;
            padding-bottom: 0;
            padding-top: 0;

            &:not(:last-child) {
              &:after {
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 0.0625rem;
                height: 0.6875rem;
                background-color: $gb_grey-650;
              }
            }

            a {
              padding: 0;
              height: 2.3rem;
              width: 2.5rem;
              display: block;
              text-align: center;
              font-size: 0.75rem;
              line-height: 2.5rem;
            }
          }
        }

        // state
        &.currentCountry {
          > a {
            color: var(--gb-brand-primary);
            font-weight: $font-weight-medium;
          }

          .country-switch-languages {
            .currentLanguage {
              a {
                color: var(--gb-brand-primary);
                font-weight: $font-weight-medium;
              }
            }
          }
        }
      }

      a::before {
        display: none;
      }
    }
  }
}

.pd-table {
  $element: &;
  position: relative;

  &--comparison {
    #{$element}__column {
      &--fixed {
        box-shadow: $box-shadow-responsive-table-right;

        #{$element}__column__cell {
          justify-content: flex-start;
          overflow: hidden;

          a {
            font-size: 0.75rem;
            font-weight: $font-weight-regular;
          }
        }

        #{$element}__column__head {
          align-items: flex-start;
          padding: 0 0 0.625rem;

          .c-tpp-area {
            min-height: 0;
          }
        }

        #{$element}__column__cell__fixed-wrapper {
          justify-content: flex;
          flex-flow: column wrap;
          align-items: flex-start;
          height: 100%;

          /* ie11 fix for text overflow */
          width: 100%;
          a {
            width: 100%;
          }

          > img {
            align-self: flex-start;
          }

          a.arrow {
            flex-direction: row;
            display: flex;
            padding-top: 0.4375rem;
            line-height: 1rem;

            &::before {
              padding-top: 0.1875rem;

              @include breakpoint(large) {
                padding-top: 0.25rem;
              }
            }
          }
        }
      }
    }

    #{$element}__column__head {
      font-size: 1rem;
      font-weight: $font-weight-medium;
      padding: 0.625rem;
      justify-content: flex-end;

      @include breakpoint(medium) {
        font-size: 0.9375rem;
      }
    }

    .web20-icon-close::before {
      font-size: 0.9375rem;
    }

    &.has-background--grey-050 .pd-table__column.pd-table__column--middle {
      .pd-table__column__cell:nth-child(even) {
        background-color: $gb_grey_050;
      }
    }
  }

  &--striped {
    .pd-table__column__cell:nth-child(odd) {
      background-color: $gb_grey_050;
    }
  }

  &__column {
    flex-shrink: 0;

    &--fixed {
      background-color: $gb_white;
      position: absolute;
      z-index: 10;

      #{$element}__column {
        &__cell {
          padding-left: 0;
        }

        &__head {
          background-color: $gb_white;
        }
      }
    }

    &__head {
      align-items: flex;
      border-right: 1px solid $gb_grey_075;
      display: flex;
      flex-direction: column;
      font-weight: $font-weight-medium;
      justify-content: center;
      padding: 0.625rem;

      > span {
        display: block;
        text-align: center;
        width: 100%;
        // for reset .c-tpp-area
        min-height: 0;
      }

      img {
        display: inline-block;
        height: 1.875rem;
        margin: 0 auto 0.3125rem;
        width: 1.875rem;
      }
    }

    &__cell {
      align-items: center;
      border-right: 1px solid $gb_grey_075;
      border-top: 1px solid $gb_grey_075;
      display: flex;
      justify-content: center;
      padding: 0.625rem;

      &__fixed-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img,
        .c-product-thumbnail {
          display: inline-block;
          width: 3.125rem;
          height: 3.125rem;
          margin-right: 0.625rem;
        }
      }
    }

    &--swipe-left {
      transition: transform 0.5s ease-in 0s;
      transform: translateX(-200%);
    }

    &--swipe-right {
      transition: transform 0.5s ease-in 0s;
      transform: translateX(0);
    }
  }

  .slider--navigation {
    button.align-middle {
      background-color: $gb_white;
      height: auto;
      padding: 3px;
      position: sticky;
      top: 50%;
      z-index: 11;

      &.arrow-right {
        right: 0;

        .icon::before {
          color: $gb_grey_400;
        }

        &.is--active .icon::before {
          color: $gb_black;
        }
      }

      &.arrow-left {
        left: 0;

        .icon::before {
          color: $gb_grey_400;
        }

        &.is--active .icon::before {
          color: $gb_black;
        }
      }
    }

    .icon {
      font-size: 1.2rem;

      @include breakpoint(medium) {
        font-size: 1.375rem;
      }

      &.web20-icon-watch-list-added::before {
        color: $gb_brand-utility-warning;
      }

      &::before {
        color: $gb_black;
      }
    }
  }

  .c-progress-bar {
    bottom: 0;
    position: sticky;
    left: 50%;
    width: 100%;
    padding: 10px;
    background-color: $gb_white;
    box-shadow: 0 -10px 10px -9px $gb_grey_200;
    z-index: 11;

    &--fullwidth {
      padding-left: 0px;
      padding-right: 0px;
      width: 101%;
    }

    .c-progress-bar__dot {
      padding: 0;
      margin-top: 10px;
    }
  }
}

.table-container--responsive {
  th,
  td {
    width: 33%;
  }
}

.c-product-tiles,
.c-subcategory-tiles,
.c-tiles {
  &--carousel,
  &__carousel {
    position: relative;
    display: block;
    height: 100%;
    margin-bottom: 0;

    .c-tiles__content {
      padding: 2rem $spacing-xs 2rem $spacing-xs;
    }

    .slick-slide:only-child {
      .c-product-tile {
        width: 100%;

        @include breakpoint(medium) {
          width: 50%;
        }
      }
    }

    .c-product-tiles__column {
      height: 100%;
    }
  }

  .slick-track {
    margin: 0;
    padding-bottom: $spacing-m;
    display: flex;
  }

  .slick-slide {
    display: flex;
    height: auto;

    & > div {
      height: 100%;
      width: 100%;
      @include xy-gutters($gutters: $grid-container-padding, $gutter-type: padding);
    }
  }

  .slick-arrow {
    background: rgba(255, 255, 255, 0.6);
    width: 3.125rem;
    height: 3.125rem;
    overflow: hidden;
    position: absolute;
    top: calc(50% - 30px);
    z-index: 5;
    color: transparent;
    transform: translateY(-50%);
    transition: opacity 0.25s ease;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 2.1875rem;
      color: $gb_black;
      transform: translate(-50%, -50%);
    }

    &.slick-disabled {
      opacity: 0;
      cursor: default;
    }

    &.slick-prev {
      left: 0;

      &::after {
        @include web20-icon('chevron-large-left');
      }
    }

    &.slick-next {
      right: 0;

      &::after {
        @include web20-icon('chevron-large-right');
      }
    }

    @include breakpoint(xxlarge) {
      background: transparent;

      &::after {
        color: $gb_grey_400;
      }

      &:hover::after {
        color: $gb_black;
      }

      &.slick-prev {
        left: -3.125rem;
      }

      &.slick-next {
        right: -3.125rem;
      }
    }
  }

  .slick-dots {
    list-style-type: none;
    margin: 0 auto;
    text-align: center;

    li {
      margin: 0 5px;
      padding: 0;
      display: inline-block;
    }

    button {
      background: $gb_grey_200;
      width: 1.875rem;
      height: 3px;
      display: block;
      overflow: hidden;
      color: transparent;
      cursor: pointer;
    }

    li.slick-active button {
      background: var(--gb-brand-primary);
    }
  }
}

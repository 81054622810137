.c-video-content {
  overflow: hidden;

  &__border {
    margin-bottom: $section-padding;
    padding-bottom: 5.125rem;
    border-bottom: $border-black;

    &--disable-border {
      border: none;
      padding-bottom: 0;
    }
  }

  &__wrapper {
    width: 100%;
  }

  &--full {
    .video-player {
      background: $gb_black;
      position: relative;
      z-index: 10; // to cover video title when video is played
    }

    .mi-poster,
    .mi-player {
      margin: auto;
      max-width: calc(
        (100vh - 60px) / 9 * 16
      ); // calc width based on view port height and image resolution
      max-height: calc(100vh - 60px); //TODO replace 60px with header height of new navigation
    }
  }

  &__video-title {
    max-height: 100%;
    padding-top: 15%;
    padding-bottom: 15%;
    position: absolute;
    top: 0;
    right: 10%;
    bottom: 0;
    left: 10%;
    z-index: 10;
    pointer-events: none;

    .h2 {
      margin-bottom: 0;
    }

    .button {
      position: absolute;
      bottom: 15%;
      left: 0.625rem;
    }

    @include breakpoint(medium) {
      padding-top: 0;
      padding-bottom: 5rem;
      top: 33%;
      bottom: auto;

      .button {
        bottom: 0;
        left: 0.9375rem;
      }
    }

    &--black {
      .h2 {
        color: $gb_black;
      }

      .button {
        color: $gb_black;
        border-color: $gb_black;
      }
    }

    &--white {
      .h2 {
        color: $gb_white;
      }

      .button {
        color: $gb_white;
        border-color: $gb_white;
      }
    }
  }

  &__wrapper .c-video-content__video {
    flex-basis: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    height: 100%;
    min-height: 15.625rem;

    @include breakpoint(medium) {
      flex-basis: 50%;
    }
  }

  .c-video-content__video-module {
    margin: 0;
    padding: 0 0 56.25%;
    height: 0;
    position: relative;
    background: center no-repeat;
    background-size: cover;

    .mi-dropdown-list li {
      padding: 0;

      a {
        padding: 0.5rem 1.875rem 0.5rem 1.25rem;
        text-decoration: none;
      }
    }

    .mi-controls.controls {
      width: 100% !important;
    }

    .mi-player-generator,
    .mi-big-play,
    .big-play-button {
      display: none !important;
    }

    a {
      margin-left: 0;

      &::before {
        display: none;
      }
    }

    .mi-error {
      position: absolute;
      padding-top: 50%;
    }
  }
}
